import React, { useRef, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useNavigate } from 'react-router-dom';

const QrCodeScanner = () => {
  const qrRef = useRef(null);
  const navigate = useNavigate();

  const extractPath = (url) => {
    const baseUrl = "https://briszaasia.com";
    return url.replace(baseUrl, '');
  }

  const startScanner = () => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "qr-reader",
      { qrbox: 250, rememberLastUsedCamera: true, focusMode: "continuous" },
      /* verbose= */ false
    );

    const handleScanSuccess = (decodedText, decodedResult) => {
      console.log(`Code scanned = ${decodedText}`, decodedResult);
      console.log('extractPath(decodedText)', extractPath(decodedText))
      // Perform your logic here after QR code is scanned
      // Optionally stop the scanner here if you want to scan only one QR code
      html5QrcodeScanner.clear();
      navigate(`${extractPath(decodedText)}`, { replace: true });
      navigate(0)
    };

    const handleScanFailure = (error) => {
      console.log(`Scanning failed. Error: ${error}`);
    };

    html5QrcodeScanner.render(handleScanSuccess, handleScanFailure);
  };

  return (
    <div style={{
      display: 'flex', // Make the parent a flex container
      flexDirection: 'column', // Stack children vertically
    }}>
      {/* <button
        style={{
          backgroundColor: '#4CAF50', // Green background
          color: 'white', // White text
          padding: '10px 15px', // Padding
          border: 'none', // No border
          borderRadius: '4px', // Rounded corners
          cursor: 'pointer', // Pointer cursor on hover
          fontSize: '16px', // Font size
          flexShrink: 0, // Prevent the button from shrinking
          // Add more styling as needed
        }}
        onClick={startScanner}>
        Abrir cámara
      </button> */}
      <div
        id="qr-reader"
        ref={qrRef}
        style={{
          flexGrow: 1, // Allow the QR reader to grow to fill available space
          minHeight: 0, // Set minimum height to 0 to allow shrinking below content size
        }}>
      </div>
    </div>

  );
};

export default QrCodeScanner;