import React from 'react'
import { Page, Text, View, Image } from "@react-pdf/renderer";

export function frontDesignPdf(data) {
  return (
    <Page size={[499, 794]} orientation="portrait" style={{ backgroundColor: '#000000' }}>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: '#b48f6d',
        }}
      >
        <Image
          src={{ uri: "https://firebasestorage.googleapis.com/v0/b/brizsa-app.appspot.com/o/texturaCarnet-min.jpg?alt=media&token=fa075d44-2c2d-47d9-800e-2aa1133fe82a" }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            resizeMode: 'cover',
            opacity: 0.5,
          }}
        />
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Image
            src={{ uri: 'https://firebasestorage.googleapis.com/v0/b/brizsa-app.appspot.com/o/brisza-removebg.png?alt=media&token=aa51acf9-2e19-4c5e-a4d1-0c16c0b74ea9' }}
            style={{
              height: 450,
              width: 450,
            }}
          />
        </View>
        <View style={{ flex: 1, position: 'absolute', bottom: 20, alignSelf: 'center' }}>
          <Text style={{ color: 'white', marginBottom: 5, fontSize: 30, alignSelf: 'flex-end', fontFamily: 'LeGrand' }}>Verano 25'</Text>
        </View>
      </View>
    </Page>
  )
}